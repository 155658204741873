import React, { useEffect, useState } from 'react';
import './theGrid.css'

import {imageData} from "../assets/data/imageData";

const TheGrid = () => {
    const [tiles, setTiles] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const createTiles = () => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const tileMinSize = isMobile ? 60 : 100;

            let rows = Math.floor(screenHeight / tileMinSize / 2);
            let cols = Math.floor(screenWidth / tileMinSize / 2);
            let newTiles = [];
            for (let i = 0; i < rows * cols; i++) {
                const width = Math.floor(Math.random() * 2) + 1; // Larghezza tra 1 e 2 unità
                const height = Math.floor(Math.random() * 2) + 1; // Altezza tra 1 e 2 unità
                newTiles.push({ width, height });
            }

            setTiles(newTiles);
        };

        // Create tiles on mount and on isMobile change
        createTiles();

        // Set up interval to create tiles every 5 seconds
        const intervalId = setInterval(createTiles, 1000);

        // Clear interval on unmount
        return () => clearInterval(intervalId);
    }, [isMobile]);

    return (
        <div className="grid-container" >
            {tiles.map((tile, index) => (
                <div
                    key={index}
                    className="grid-item"
                    style={{
                        gridColumn: `span ${tile.width}`,
                        gridRow: `span ${tile.height}`,
                    }}
                >
                    <div className="absolute">
                        {index}
                    </div>
                    <div
                        className="content"
                        style={{
                            backgroundImage: `url(${imageData[index]})`
                        }}>

                    </div>
                </div>
            ))}
        </div>
    );
};
export default TheGrid;


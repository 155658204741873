import sadPokemon from '../images/pokemon-sad.jpeg';
import orsoImage from '../images/orso-compleanno.jpeg';
import afterShrek from '../images/after-shrek.jpeg';
import empathy from '../images/empathy.jpeg';
import noWrong from '../images/no-wrong.jpeg';
import soffrireFaBene from '../images/soffrire-fa-bene.jpeg'
import fucilozzo from '../images/fucilozzo.jpeg'

import notFound from '../images/notFound.png'
export const imageData = [
    sadPokemon,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound,
    notFound
    //orsoImage,
    //afterShrek,
    //empathy,
    //noWrong,
    //soffrireFaBene,
    //fucilozzo,
    //SI RIPETONO
]